<script>
import {AgGridVue} from "ag-grid-vue";
import {mapState} from "vuex";
import StatusDisplayCell from "../grid/StatusDisplayCell";
import DateCell from "../grid/generic/DateCell";
import ActionsDisplayCell from "../grid/ActionsDisplayCell";
import store from '@/store';
import router from "@/router";
import client from "../../services/client";

export default {
  name: "PriceListsGrid",
  data() {
    return {
      columnDefs: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        floatingFilter: true,
      },
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AgGridVue, StatusDisplayCell, DateCell, ActionsDisplayCell
  },
  beforeMount() {
    this.columnDefs = [
      {
        field: "filename",
        resizable: true,
        headerName: "Pricelist Name",
        filter: 'agTextColumnFilter',
        filterParams: {
          suppressAndOrCondition: true,
        },
        cellStyle: {'text-align': 'left'},
        width: 300,
        cellRenderer: params => {
          return `<a href="${client.backendUrl + 'pricelists/download/' + params.data.storage_filename}" target="_blank">${params.value}</a>`;
        }
      },
      {
        field: "status",
        headerName: "Status",
        width: 140,
        cellRenderer: 'StatusDisplayCell',
        cellStyle: {'text-align': 'left'},
      },
      {
        field: "supplier.name", headerName: "Supplier", width: 160, cellStyle: {'text-align': 'left'},
        filter: 'agTextColumnFilter',
        resizable: true,
        filterParams: {
          suppressAndOrCondition: true,
        }
      },
      {field: "currency.code", headerName: "Currency", width: 160, cellStyle: {'text-align': 'left'}},
      {field: "uploader.name", headerName: "Created By", width: 150, cellStyle: {'text-align': 'left'}},
      {
        field: "records_added",
        headerName: "Records",
        width: 150,
        cellStyle: {'text-align': 'left'},
        cellRenderer: params => {
          return new Intl.NumberFormat('en-us').format(params.value);
        }
      },
      {
        field: "direct_matches",
        headerName: "Matches",
        width: 150,
        cellStyle: {'text-align': 'left'},
        cellRenderer: params => {
          return new Intl.NumberFormat('en-us').format(params.value);
        }
      },
      {
        field: "created_at",
        headerName: "Uploaded on",
        width: 210,
        cellStyle: {'text-align': 'left'},
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {

            if (cellValue == null) {
              return 0;
            }

            const date = cellValue.split('T');

            const dateParts = date[0].split('-');
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) -1;
            const year = Number(dateParts[0]);

            const cellDate = new Date(year, month, day);

            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
          suppressAndOrCondition: true
        },
        cellRenderer: 'DateCell'
      },
      {
        field: "id",
        headerName: "Actions",
        width: 240,
        cellStyle: {'text-align': 'left'},
        cellRenderer: 'ActionsDisplayCell',
        cellRendererParams: {
          actions: [
            {
              type: 'danger',
              label: 'Remove',
              clickHandler: {
                type: 'dispatch',
                action: 'pricelists/deletePricelist',
                onSuccess: function () {
                  store.dispatch('pricelists/fetchPricelists');
                }
              },
            },
            {
              type: 'primary',
              label: 'View',
              clickHandler: {
                type: 'method',
                action: function (cellValue) {
                  router.push('pricelists/' + cellValue + '/records');
                }
              }
            },
            {
              type: 'info',
              label: 'Re-process',
              clickHandler: {
                type: 'dispatch',
                action: 'pricelists/reProcessPricelist',
                onSuccess: function () {
                  store.dispatch('pricelists/fetchPricelists');
                }
              }
            }
          ]
        }
      }
    ];
  },
  mounted() {
    this.fetchPricelists();
  },
  methods: {
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    fetchPricelists() {
      this.$store.dispatch('pricelists/fetchPricelists');
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.sizeToFit();
    }
  },
  computed: mapState({
    rowData: state => state.pricelists.pricelists,
    isLoading: state => state.pricelists.isFetching
  })
};
</script>

<template>
  <b-overlay :show="this.isLoading" rounded="sm">
    <ag-grid-vue style="width: 100%; min-height: 730px; height: 77vh"
                 :defaultColDef="defaultColDef"
                 class="ag-theme-alpine"
                 :columnDefs="columnDefs"
                 :rowData="rowData">
    </ag-grid-vue>
  </b-overlay>
</template>
