<template>
  <b-modal id="price-list-modal" size="lg" title="New Price list" v-model="showPricelistModal">
    <b-overlay :show="loading" rounded="sm">
      <p>This upload uses existing templates to parse pricelists. Please select a file and specify which Supplier it belongs to. If a supplier doesn't exist in this list, use the <strong>Generic - Any Supplier</strong> list.</p>

      <b-alert variant="info" :show="showAlert">Please ensure the price list matches <strong><a href="assets/PriceListExample.xlsx" target="_blank">this format</a></strong> and
        includes a supplier column.
      </b-alert>

      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="supplier-select">Select Supplier</label>
          </div>
          <div class="formElement__input">
            <v-select name="supplier-select" id="supplier-select" :placeholder="`Please select`" v-model="supplier" label="optionLabel"
                      :options="suppliers"></v-select>
          </div>
        </div>
      </div>
      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="currency-select">Currency</label>
          </div>
          <div class="formElement__input">
            <v-select name="currency-select" id="currency-select" :placeholder="`Please select`" v-model="currency"
                      :options="['EUR', 'GBP', 'USD', 'CAD', 'CHF']"></v-select>
          </div>
        </div>
      </div>
      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="price-date">Price date</label>
          </div>
          <div class="formElement__input">
            <datepicker placeholder="Select Date" v-model="date"></datepicker>
          </div>
        </div>

        <div class="formElement">
          <div class="formElement__label">
            <label for="price-expiry">Expiry</label>
          </div>
          <div class="formElement__input">
            <v-select name="expiry-select" id="expiry-select" :placeholder="`Please select`"
                      v-model="expiry"
                      :options="['1 month', '2 months', '3 months', '4 months', '6 months', '12 months']"></v-select>
          </div>
        </div>

      </div>
      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="file-upload">Upload file</label>
          </div>
          <div class="formElement__input">
            <b-form-file
                :plain="true"
                v-model="file"
                :state="Boolean(file)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
            ></b-form-file>
          </div>
        </div>


      </div>

    </b-overlay>
    <template #modal-footer>

      <b-button variant="success" @click="tryUpload">
        Upload
      </b-button>
      <b-button variant="light" @click="cancelUpload">
        Cancel
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

export default {
  name: "ExistingTemplatePriceListModal",
  data() {
    return {
      file: null,
      supplier: null,
      currency: null,
      loading: false,
      date: new Date(),
      expiry: null,
      suppliers: [
        {
          optionLabel: 'Generic - Any Supplier',
          optionValue: 'Generic'
        }
      ]
    }
  },
  components: {
    Datepicker
  },
  computed: {
    showPricelistModal: {
      get() {
        return this.$store.state.pricelists.modals.isAddModalOpen
      },
      set(v) {
        if (v) {
          this.$store.dispatch('pricelists/closeAddModal');
        } else {
          this.$store.dispatch('pricelists/openAddModal');
        }
      }
    },
    showAlert() {
      return this.supplier && this.supplier.optionValue === 'Generic';
    }
  },
  methods: {
    tryUpload() {
      this.loading = true;
      this.$store.dispatch('pricelists/uploadPricelist', {
        file: this.file,
        supplier: this.supplier.optionValue,
        currency: this.currency,
        price_date: this.formatDate(this.date)
      }).then(() => {
        this.$store.dispatch('pricelists/fetchPricelists');
        this.reset();
      });
    },
    cancelUpload() {
      this.loading = false;
      this.$store.dispatch('pricelists/closeAddModal');
    },
    reset() {
      this.file = null;
      this.supplier = null;
      this.loading = false;
      this.currency = null;
    }
  }

}
</script>
