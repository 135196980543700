<template>
  <b-modal id="price-list-modal" size="lg" title="New Price list from Custom template" v-model="showModal">
    <b-overlay :show="loading" rounded="sm">
      <p>This tool allows you to create a Price list from an arbitrary Excel file. Please specify columns for various
        data points below and click "Upload" to process the file</p>

      <b-alert variant="danger" :show="this.errors.length > 0">
        <ul>
          <li v-for="error in this.errors" :key="error">{{ error }}</li>
        </ul>
      </b-alert>


      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="supplier-select">Supplier <span class="text-danger">*</span></label>
          </div>
          <div class="formElement__input">
            <v-select name="supplier-select" id="supplier-select" taggable :placeholder="`Please select`"
                      :create-option="supplierName => ({ name: supplierName, id: 0})"
                      v-model="supplier" label="name"
                      :options="suppliers"></v-select>
          </div>
        </div>
        <div class="formElement">
          <div class="formElement__label">
            <label for="currency-select">Currency <span class="text-danger">*</span></label>
          </div>
          <div class="formElement__input">
            <v-select name="currency-select" id="currency-select" :placeholder="`Please select`" v-model="currency"
                      :options="['EUR', 'GBP', 'USD', 'CAD', 'CHF']"></v-select>
          </div>
        </div>
      </div>
      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="price-date">Price date</label>
          </div>
          <div class="formElement__input">
            <datepicker placeholder="Select Date" v-model="date"></datepicker>
          </div>
        </div>

        <div class="formElement">
          <div class="formElement__label">
            <label for="price-expiry">Expiry</label>
          </div>
          <div class="formElement__input">
            <v-select name="expiry-select" id="expiry-select" :placeholder="`Please select`"
                      v-model="expiry"
                      :options="['Supplier Default', '1 day', '2 days', '1 week', '2 weeks', '3 weeks', '1 month', '2 months', '3 months', '4 months', '5 months', '6 months',
                      '7 months', '8 months', '9 months', '10 months', '11 months', '12 months']"></v-select>
          </div>
        </div>

      </div>
      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="file-upload">Upload file</label>
          </div>
          <div class="formElement__input">
            <b-form-file
                :plain="true"
                v-model="file"
                :state="Boolean(file)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
            ></b-form-file>
          </div>
        </div>
        <div class="formElement">
          <div class="formElement__label">
            <label for="pricelist-type">Pricelist Type <span class="text-danger">*</span></label>
          </div>
          <div class="formElement__input">
            <v-select name="pricelist-type" id="pricelist-type" :placeholder="`Please select`"
                      v-model="price_list_type"
                      :reduce="option => option.value"
                      :options="[
                             {
                        label: 'Please Select',
                        value: ''
                      },
                      {
                        label: 'Monthly/weekly',
                        value: 'regular'
                      },
                      {
                        label: 'Annual',
                        value: 'annual'
                      },
                      {
                        label: 'Spot Offer',
                        value: 'spot'
                      }]"></v-select>
          </div>
        </div>
      </div>

      <div class="formRow">
        <div class="formElement">
          <b-form-checkbox
              v-model="forceCategoryOverride"
              class="form-check"
              id="customControlInline"
              name="checkbox-1"
              value="1"
              unchecked-value="0"
          >
            Use for Latina sale prices regardless of Supplier settings
          </b-form-checkbox>
        </div>
      </div>

      <div class="mappingTemplate">
        <h4>Mapping fields</h4>
        <p>Please specify the character for each data column below. Columns marked with (<span
            class="text-danger">*</span>) are required and must be provided</p>
        <div class="formRow">
          <div class="formElement">
            <div class="formElement__label">
              <label for="suppler-article-title">Title <span class="text-danger">*</span></label>
            </div>
            <div class="formElement__input">
              <b-form-input name="supplier-article-title" v-model="template.title" type="text"
                            placeholder="Optional"/>
            </div>
          </div>
          <div class="formElement">
            <div class="formElement__label">
              <label for="suppler-article-number">Supplier Art. Ref.</label>
            </div>
            <div class="formElement__input">
              <b-form-input name="supplier-article-number" v-model="template.supplier_article_number" type="text"
                            placeholder="Optional"/>
            </div>
          </div>
        </div>
        <div class="formRow">
          <div class="formElement">
            <div class="formElement__label">
              <label for="latina-article-title">Latina Art. Ref.</label>
            </div>
            <div class="formElement__input">
              <b-form-input name="latina-article-title" v-model="template.latina_article_id" type="text"
                            placeholder="Optional"/>
            </div>
          </div>
          <div class="formElement">
            <div class="formElement__label">
              <label for="volume-unit">Volume Unit <span class="text-danger">*</span></label>
            </div>
            <div class="formElement__input custom-select">
              <v-select v-model="template.volume_unit" :options="volumeOptions" label="text"
                        :reduce="option => option.value"></v-select>
            </div>
          </div>
        </div>
        <div class="formRow">
          <div class="formElement">
            <div class="formElement__label">
              <label for="bottles-per-case">Bottles per case</label>
            </div>
            <div class="formElement__input">
              <b-form-input name="bottles-per-case" v-model="template.bottles_per_case" type="text"
                            placeholder="Optional"/>
            </div>
          </div>
          <div class="formElement">
            <div class="formElement__label">
              <label for="volume-liters">Volume per unit</label>
            </div>
            <div class="formElement__input">
              <b-form-input name="volume-liters" v-model="template.volume_liters" type="text"
                            placeholder="Optional"/>
            </div>
          </div>
        </div>
        <div class="formRow">
          <div class="formElement">
            <div class="formElement__label">
              <label for="unit-price">Price/unit <span class="text-danger">*</span></label>
            </div>
            <div class="formElement__input">
              <b-form-input name="unit-price" v-model="template.price_bottle" type="text"
                            placeholder="Required"/>
            </div>
          </div>
          <div class="formElement">
            <div class="formElement__label">
              <label for="case-price">Price/case</label>
            </div>
            <div class="formElement__input">
              <b-form-input name="case-price" v-model="template.price_case" type="text"
                            placeholder="Optional"/>
            </div>
          </div>
        </div>
        <div class="formRow">
          <div class="formElement">
            <div class="formElement__label">
              <label for="alcohol-percentage">Alc. %</label>
            </div>
            <div class="formElement__input">
              <b-form-input name="alcohol-percentage" v-model="template.alcohol_percentage" type="text"
                            placeholder="Optional"/>
            </div>
          </div>
          <div class="formElement">
            <div class="formElement__label">
              <label for="available-stock">Available Stock</label>
            </div>
            <div class="formElement__input">
              <b-form-input name="available-stock" v-model="template.available_stock" type="text" id="available-stock"
                            placeholder="Optional"/>
            </div>
          </div>
        </div>
        <div class="formRow">
          <div class="formElement">
            <div class="formElement__label">
              <label for="is-refillable">Is RF?</label>
            </div>
            <div class="formElement__input">
              <b-form-input name="is-gift-box" v-model="template.is_refillable" type="text"
                            placeholder="Optional"/>
            </div>
          </div>
          <div class="formElement">
            <div class="formElement__label">
              <label for="is-refillable">Is Refillable Value</label>
            </div>
            <div class="formElement__input">
              <b-form-input name="is-gift-box" v-model="template.is_refillable_value" type="text"
                            placeholder="Optional"/>
            </div>
          </div>
        </div>
        <div class="formRow">
          <div class="formElement">
            <div class="formElement__label">
              <label for="is-gift-box">Gift box?</label>
            </div>
            <div class="formElement__input">
              <b-form-input name="is-gift-box" v-model="template.is_gift_box" type="text"
                            placeholder="Optional"/>
            </div>
          </div>
          <div class="formElement">
            <div class="formElement__label">
              <label for="is-gift-box-value">Gift box value</label>
            </div>
            <div class="formElement__input">
              <b-form-input name="is-gift-box-value" v-model="template.is_gift_box_value" type="text"
                            placeholder="Optional"/>
            </div>
          </div>
        </div>
        <div class="formRow">
          <div class="formElement">
            <div class="formElement__label">
              <label for="ean-article">EAN/UPC Article</label>
            </div>
            <div class="formElement__input">
              <b-form-input name="ean-article" v-model="template.ean_article" type="text"
                            placeholder="Optional"/>
            </div>
          </div>
          <div class="formElement">
            <div class="formElement__label">
              <label for="ean-case">EAN/UPC Case</label>
            </div>
            <div class="formElement__input">
              <b-form-input name="ean-case" v-model="template.ean_case" type="text"
                            placeholder="Optional"/>
            </div>
          </div>
        </div>
        <div class="formRow">
          <div class="formElement">
            <div class="formElement__label">
              <label for="ean-case">Products Type (T1/T2)</label>
            </div>
            <div class="formElement__input">
              <b-form-input name="ean-case" v-model="template.price_product_type" type="text"
                            :disabled="productTypeOverride === false"
                            placeholder="Optional"/>
              <b-form-checkbox
                  v-model="productTypeOverride"
                  class="form-check mx-1"
                  :value="true"
                  :unchecked-value="false"
              >
                Override
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>

    </b-overlay>
    <template #modal-footer>

      <b-button variant="success" @click="upload">
        Upload
      </b-button>
      <b-button variant="light" @click="cancelUpload">
        Cancel
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {mapState} from "vuex";

export default {
  name: "CustomTemplatePricelistModal",
  data() {
    return {
      errors: [],
      file: null,
      supplier: null,
      expiry: 'Supplier Default',
      currency: 'EUR',
      price_list_type: '',
      loading: false,
      date: new Date(),
      template: {
        supplier_article_number: null,
        latina_article_id: null,
        title: null,
        alcohol_percentage: null,
        bottles_per_case: null,
        volume_liters: null,
        price_bottle: null,
        price_case: null,
        available_stock: null,
        is_gift_box: null,
        is_gift_box_value: null,
        ean_case: null,
        ean_article: null,
        volume_unit: null,
        is_refillable: null,
        is_refillable_value: null,
        price_product_type: null,
        force_supplier_category_override: false,

      },
      volumeOptions: [
        {value: 'liter', text: 'Liter'},
        {value: 'deciliter', text: 'Deciliter'},
        {value: 'centiliter', text: 'Centiliter'}
      ],
      forceCategoryOverride: false,
      productTypeOverride: false
    }
  },
  components: {
    Datepicker
  },
  watch: {
    supplier: function (newSupplier) {

      const supplierProductType = newSupplier.default_product_type;

      if (supplierProductType && ['T1','T2'].includes(supplierProductType)) {
          this.template.price_product_type = newSupplier.default_product_type;
          this.productTypeOverride = false;
      } else {
          this.productTypeOverride = true;
      }
    }
  },
  computed: {
    showModal: {
      get() {
        return this.$store.state.pricelists.modals.isNewTemplateModalOpen
      },
      set(v) {
        if (v) {
          this.$store.dispatch('pricelists/closeCustomAddModal');
        } else {
          this.$store.dispatch('pricelists/openCustomAddModal');
        }
      }
    },
    ...mapState({
      suppliers: state => state.ancillaries.suppliers
    })
  },
  methods: {

    upload() {
      if (!this.validate()) {
        return false;
      }

      this.loading = true;
      this.$store.dispatch('pricelists/uploadCustomPricelist', {
        file: this.file,
        supplier: this.supplier,
        currency: this.currency,
        expiry: this.expiry,
        price_list_type: this.price_list_type,
        price_date: this.formatDate(this.date),
        force_supplier_category_override: this.forceCategoryOverride,
        force_product_type_override: this.productTypeOverride,
        template: this.template
      }).then(() => {
        this.$store.dispatch('pricelists/fetchPricelists');
        this.reset();
      });
    },
    cancelUpload() {
      this.$store.dispatch('pricelists/closeCustomAddModal');
      this.reset();
    },
    reset() {
      this.loading = false;
    },
    validate() {

      this.errors = [];

      if (!this.supplier) {
        this.errors.push('Please select or enter a Supplier.');
      }

      if (this.price_list_type === '') {
        this.errors.push('Please select a Price list type.');
      }

      if (!this.currency) {
        this.errors.push('Please select Currency.');
      }

      if (!this.template.title) {
        this.errors.push('Please select column for Title.');
      }

      if (!this.template.volume_unit) {
        this.errors.push('Please select Volume unit.');
      }

      if (!this.template.price_product_type) {
        this.errors.push('Please select a Products Type (T1 or T2).');
      }

      if (this.errors.length === 0) {
        return true;
      }

      this.$toast.error('Please correct errors!');

    }
  }

}
</script>

<style lang="scss">

.mappingTemplate {

  font-size: 13px;
  padding: 10px;
  background: #ededed;
  border: solid 1px #ddd;
  border-radius: 5px;
  margin-top: 20px;

  h4 {
    font-size: 14px;
  }

  .form-control {
    width: 120px;
    font-size: 12px;
  }

  .formRow {
    margin-bottom: 3px;
  }

  .formElement {
    min-width: 35%;
    flex-grow: 0;

    &__input {
      min-width: 160px;

      &.custom-select {
        min-width: 120px;
        background: #fff;
      }
    }

    &__label {
      width: 160px;

      label {
        text-align: left;
      }
    }

  }
}

.vs__selected-options {
  flex-wrap: nowrap !important;
}

</style>
