<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Price Lists</h4>

          <div class="page-title-right">
            <b-dropdown text="Upload Price list" variant="primary" right>
              <b-dropdown-item @click="openModal('existing')">Existing Templates</b-dropdown-item>
              <b-dropdown-item @click="openModal('custom')">New template</b-dropdown-item>
            </b-dropdown>

          </div>
        </div>
      </div>
      <div class="col-12">
        <PriceListsGrid/>
      </div>
    </div>
    <ExistingTemplatePriceListModal/>
    <CustomTemplatePricelistModal />
  </Layout>
</template>

<script>
import Layout from '../layouts/horizontal.vue';
import PriceListsGrid from "../components/pricelists/PriceListsGrid";
import ExistingTemplatePriceListModal from "../components/pricelists/PriceListModal";
import CustomTemplatePricelistModal from "../components/pricelists/CustomTemplatePricelistModal";

export default {
  name: 'Pricelists',
  components: {
    ExistingTemplatePriceListModal,
    CustomTemplatePricelistModal,
    PriceListsGrid,
    Layout
  },
  created() {
    this.$store.dispatch('ancillaries/fetchSuppliers');
  },
  methods: {
    openModal(modalName) {

      switch (modalName) {
       case 'existing':
         this.$store.dispatch('pricelists/openAddModal');
         break;

       case 'custom':
         this.$store.dispatch('pricelists/openCustomAddModal');
         break;
      }
    }
  },
}
</script>
